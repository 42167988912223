import { AgeGroup, LanguageCodes } from "../../models";
import {
    CHECK_EMR_VALIDITY,
    CHECK_EMR_VALIDITY_FAILED, CHECK_EMR_VALIDITY_RESPONSE,
    CLEAR_ALL_DATA, CLEAR_EMR_CHECK,
    SET_APPOINTMENT_ID,
    SET_DOB,
    SET_EMERGENCY_CANSHARE,
    SET_EMERGENCY_CONTACT,
    SET_EMERGENCY_EMAIL,
    SET_EMERGENCY_FIRSTNAME,
    SET_EMERGENCY_LASTNAME,
    SET_EMERGENCY_PHONENUMBER,
    SET_EMERGENCY_RELATIONTYPE,
    SET_EMR_NUMBER,
    SET_FIRSTNAME,
    SET_GENDER,
    SET_GUARDIAN_EMAIL,
    SET_GUARDIAN_FIRSTNAME,
    SET_GUARDIAN_LASTNAME,
    SET_IS_LEGAL_GUARDIAN,
    SET_IS_PATIENT_CAPABLE,
    SET_LASTNAME,
    SET_LEGAL_GUARDIAN_DATA,
    SET_PASSWORD,
    SET_SIGNUP_DATA,
    SET_WHO_IS_FILLING,
    SET_ZIPCODE
} from "../actions/signupData.action";
import updateDb from "update-browserslist-db";

export interface EMRCheckResult {
    emrId?: string
    exists?: boolean,
    firstName?: string
    lastName?: string
    dob?: string
    error?: string
}

export interface SignupParams {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emrId?: string,
    password: string,
    dob: string,
    hasAcceptedTerms: boolean,
    isLegalGuardian: boolean,
    isOneAmongMultipleChildren: boolean,
    hasPrematureBirth: boolean,
    zipCode: string,
    reasonForVisit: string,
    email: string,
    guardianFirstName: string,
    guardianLastName: string,
    guardianEmail: string
    gender: string,
    ageGroup: AgeGroup | undefined
    isPatientCapable: boolean | undefined,
    guardianPhoneNumber: string,
    relationshipWithPatient: string,
    canShareEmergencyContact: boolean
    languagePreference: LanguageCodes | undefined
    emrCheck: EMRCheckResult | undefined
    appointmentId: string | undefined
}

const initialState: SignupParams = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    dob: "",
    hasAcceptedTerms: false,
    isLegalGuardian: false,
    isOneAmongMultipleChildren: false,
    hasPrematureBirth: false,
    zipCode: "",
    reasonForVisit: "",
    email: "",
    emrId: undefined,
    guardianFirstName: "",
    guardianLastName: "",
    guardianEmail: "",
    gender: "",
    ageGroup: undefined,
    isPatientCapable: undefined,
    guardianPhoneNumber: "",
    relationshipWithPatient: "",
    canShareEmergencyContact: false,
    languagePreference: undefined,
    emrCheck: undefined,
    appointmentId: undefined
}

const SignupReducer = (state: SignupParams = initialState, action: any): SignupParams => {
    switch (action.type) {
        case SET_SIGNUP_DATA:
            state = {
                ...state, emrId: action.payload.emrId, firstName: action.payload.firstName, lastName: action.payload.lastName, phoneNumber: action.payload.phoneNumber,
                password: action.payload.password, dob: action.payload.dob,
                hasAcceptedTerms: action.payload.hasAcceptedTerms, isLegalGuardian: action.payload.isLegalGuardian, isOneAmongMultipleChildren: action.payload.isOneAmongMultipleChildren,
                hasPrematureBirth: action.payload.hasPrematureBirth, zipCode: action.payload.zipCode,
                gender: action.payload.gender, ageGroup: action.payload.ageGroup, languagePreference: action.payload.preferredLanguage
            }
            return state
        case SET_EMR_NUMBER:
            state = { ...state, emrId: action.payload.emrId }
            return state
        case SET_FIRSTNAME:
            state = { ...state, firstName: action.payload.firstName }
            return state
        case SET_LASTNAME:
            state = { ...state, lastName: action.payload.lastName }
            return state
        case SET_DOB:
            state = { ...state, dob: action.payload.dob, ageGroup: action.payload.ageGroup }
            return state
        case SET_GENDER:
            state = { ...state, gender: action.payload.gender }
            return state
        case SET_ZIPCODE:
            state = { ...state, zipCode: action.payload.zipCode }
            return state
        case SET_PASSWORD:
            state = { ...state, password: action.payload.password }
            return state
        case SET_WHO_IS_FILLING:
            state = { ...state, phoneNumber: action.payload.phoneNumber, isLegalGuardian: action.payload.isLegalGuardian, isOneAmongMultipleChildren: action.payload.isOneAmongMultipleChildren, hasPrematureBirth: action.payload.hasPrematureBirth, languagePreference: action.payload.preferredLanguage }
            return state
        case SET_GUARDIAN_FIRSTNAME:
            state = { ...state, guardianFirstName: action.payload.guardianFirstName }
            return state
        case SET_GUARDIAN_LASTNAME:
            state = { ...state, guardianLastName: action.payload.guardianLastName }
            return state
        case SET_GUARDIAN_EMAIL:
            state = { ...state, guardianEmail: action.payload.guardianEmail }
            return state
        case SET_EMERGENCY_FIRSTNAME:
            state = {...state, guardianFirstName: action.payload.guardianFirstName}
            return state
        case SET_EMERGENCY_LASTNAME:
            state = {...state, guardianLastName: action.payload.guardianLastName}
            return state
        case SET_EMERGENCY_EMAIL:
            state = {...state, guardianEmail: action.payload.guardianEmail}
            return state
        case SET_EMERGENCY_PHONENUMBER:
            state = {...state, guardianPhoneNumber: action.payload.guardianPhoneNumber}
            return state
        case SET_EMERGENCY_RELATIONTYPE:
            state = {...state, relationshipWithPatient: action.payload.relationshipWithPatient}
            return state
        case SET_EMERGENCY_CANSHARE:
            state = {...state, canShareEmergencyContact: action.payload.canShareEmergencyContact}
            return state
        case SET_LEGAL_GUARDIAN_DATA:
            state = {
                ...state, guardianEmail: action.payload.guardianEmail, guardianFirstName: action.payload.guardianFirstName, guardianLastName: action.payload.guardianLastName,
            }
            return state
        case SET_EMERGENCY_CONTACT:
            state = {
                ...state, guardianEmail: action.payload.guardianEmail, guardianFirstName: action.payload.guardianFirstName, guardianLastName: action.payload.guardianLastName,
                guardianPhoneNumber: action.payload.guardianPhoneNumber, relationshipWithPatient: action.payload.relationshipWithPatient, canShareEmergencyContact: action.payload.canShareEmergencyContact
            }
            return state
        case SET_IS_LEGAL_GUARDIAN:
            state = { ...state, isLegalGuardian: action.payload.isLegalGuardian }
            return state
        case SET_IS_PATIENT_CAPABLE:
            state = { ...state, isPatientCapable: action.payload.isPatientCapable }
            return state

        case CHECK_EMR_VALIDITY_FAILED:
            state = {...state, emrCheck: {...state.emrCheck, error: action.payload.error}}
            return state

        case CHECK_EMR_VALIDITY_RESPONSE:
            state = {...state, emrCheck: action.payload}
            return state
        case CHECK_EMR_VALIDITY:
            state = {...state, emrId: undefined, emrCheck: initialState.emrCheck}
            return state
        case CLEAR_EMR_CHECK:
            state = {...state, emrId: undefined, emrCheck: initialState.emrCheck}
            return state

        case SET_APPOINTMENT_ID:
            state = { ...state, appointmentId: action.payload.appointmentId }
            return state

        case CLEAR_ALL_DATA:
            state = { ...state, ...initialState }
            return state
        default:
            return state;
    }
}

export default SignupReducer
