import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import {IPatient, IPatientSummary} from "../../models";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearScreenerQuestions, fetchPatients } from "../../store/actions/screenerQuestions.action";
import { Patients } from "./Patients";
import Pagination from "../generic/Pagination";
import TableFilterPagination from "../generic/TableFilterPagination";
import { clearUserId, logoutProvider, logoutUser } from "../../store/actions/account.action";
import { FaPowerOff } from "react-icons/fa";
import {clearPhoneNumberError} from "../../store/actions/practice.action";
import {useFlags} from "flagsmith/react";
import { clearAllData } from "../../store/actions/signupData.action";

const PatientsScreen = () => {
    const flags = useFlags(['patient_status_based_screening'])
    const isPatientStatusBasedScreeningEnabled = flags.patient_status_based_screening.enabled

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { screenName, slug } = useSelector((state: StateParams) => state.account)
    const { patients, recordsCount } = useSelector((state: StateParams) => state.screener)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [patientsRecord, setPatientsRecord] = useState<IPatientSummary[]>([]);

    useEffect(() => {
        dispatch(clearUserId())
        dispatch(clearScreenerQuestions())
    }, [])


    useEffect(() => {
        if (patients) {
            setPatientsRecord(patients)
        }
    }, [patients])

    useEffect(() => {
        if (searchParams) {
            setCurrentPage(Number(searchParams.get("page")))
        }
    }, [searchParams])

    useEffect(() => {
        if (currentPage || recordsPerPage || searchText) {
            const basePath = isPatientStatusBasedScreeningEnabled ? "/admin/patients/v2" : "/admin/patients"
            dispatch(fetchPatients(basePath, currentPage, recordsPerPage, searchText))
        }
    }, [isPatientStatusBasedScreeningEnabled, currentPage, recordsPerPage, searchText]);

    useEffect(() => {
        navigate(`/patients?page=${currentPage}`)
    }, [currentPage]);

    const logout = () => {
        dispatch(logoutProvider())
        dispatch(clearAllData())
        navigate(`/adminLogin`)
    }

    function addPatient() {
        dispatch(clearPhoneNumberError())
        navigate('/adminPatientSignupLanguagePreference')
    }

    return (
        <main className="h-screen mx-auto px-1 bg-sky-light-lg bg-no-repeat bg-cover">
            <div className="flex justify-between">
                <h1 className="text-xl font-semibold"> Patients </h1>
                <div className="flex">
                    <button className="items-center px-2 py-1 border text-sm font-medium rounded-md bg-white hover:bg-gray-50 first-letter:rounded-l-md border-orange text-orange"
                            onClick={addPatient}>
                        Add Patient
                    </button>
                    <FaPowerOff data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom" className='m-2' onClick={logout} />
                </div>
            </div>
            <TableFilterPagination
                setTableFilter={setSearchText}
                totalRows={recordsCount || 0}
                currentPageHandler={setCurrentPage}
            />
            <Patients patients={patientsRecord} />
            <Pagination
                totalRows={recordsCount || 0}
                rowsPerPage={recordsPerPage}
                recordsPerPageHandler={setRecordsPerPage}
                currentPage={currentPage}
                currentPageHandler={setCurrentPage}
            />
        </main>
    )
}

export default PatientsScreen
